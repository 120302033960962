import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Video from "./video.js";
import MyVideo from "../../videos/Clothes4All-Beach-EN_original.mp4";
import PRODUCT_VIDEOS from "../../data/products/product-videos.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "video"
    }}>{`Video`}</h1>
    <p>{`Native HTML5 video component, with custom controls for keyboard support.`}</p>

    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Video} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Video\n  sources={[MyVideo]}\n  captions={[PRODUCT_VIDEOS.en.beach.captions]}\n  descriptions={[]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Video,
      MyVideo,
      PRODUCT_VIDEOS,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Video sources={[MyVideo]} captions={[PRODUCT_VIDEOS.en.beach.captions]} descriptions={[]} mdxType="Video" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      